import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
// 机票-部分分析
import consumer_excessive_airTicket_deptAnalysis from '@/lib/data-service/haolv-default/consumer_excessive_airTicket_deptAnalysis'
import moment from "moment";

export default {
    name: "FlightBranchReport",
    data() {
        return {
            departmentList: [],
            params: {
                companyId: '',
                deptId: '',
                deptName: '',
                startTime: '',
                endTime: '',
                dateArr: [],
                deptIdArr: [''],
                timeList: '',
                pageSize: 10,
                currentPage: 1,
                total: 0
            },
            options: [],
            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }]
        };
    },
    methods: {
        // 获取部门列表
        getDepartmentList() {
            consumer_department_getDepartmentList().then(res => {
                let departmentList = res.datas.departmentListResults;
                departmentList.unshift({deptName: '全部', deptId: ''});
                this.departmentList = departmentList;
            })
        },
        changeDeptId(val) {
            let _this = this;
            function getDeptName(list, activeId) {
                list.forEach(value => {
                    if (value.deptId === activeId) {
                        _this.params.deptName = value.deptName;
                    }
                    if (value.childrenList && value.childrenList.length > 0) {
                        getDeptName(value.childrenList, activeId);
                    }
                })
            }
            if (val.length === 0) {
                this.params.deptId = '';
                this.params.deptName = '';
            } else {
                this.params.deptId = val[val.length - 1];
                getDeptName(this.departmentList, val[val.length - 1]);
            }
        },
        changeDate(val) {
            this.params.startTime = val[0];
            this.params.endTime = val[1];
        },
        setTimeList() {
            if (this.params.timeList.length === 0) {
                let now = moment();
                let year = now.year();
                let month = now.month();
                let timeList = [];
                for (let i = 1, l = month + 2; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i;
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    };
                    timeList.push(item)
                }
                this.params.timeList = timeList
            }
        },
        disabledDate(time) {
            // 获取选中时间
            let timeOptionRange = this.timeOptionRange;
            if (timeOptionRange) {
                // 如果有获取时间
                let year = moment(timeOptionRange).year();
                let timeYear = moment(time).year();
                return year !== timeYear
            } else {
                // 没有获取过时间，则全部都过
                return false
            }
        },
        onPick({maxDate, minDate}) {
            // 当选中了第一个日期还没选第二个
            // 只选中一个的时候自动赋值给minDate，当选中第二个后组件自动匹配，将大小日期分别赋值给maxDate、minDate
            if (minDate && !maxDate) {
                this.timeOptionRange = minDate;
            }
            // 如果有maxDate属性，表示2个日期都选择了，则重置timeOptionRange
            if (maxDate) {
                this.timeOptionRange = null;
            }
        },
        changeOrderDate(val) {
            console.log(val);
            if (val === '' || val === null) {
                this.params.startTime = '';
                this.params.endTime = '';
                this.params.timeList = []
            } else {
                this.params.startTime = val[0];
                this.params.endTime = val[1];

                let year = this.params.startTime.split('-')[0];
                let startMonth = parseInt(this.params.startTime.split('-')[1]);
                let endMonth = parseInt(this.params.endTime.split('-')[1]);
                let timeList = [];
                for (let i = startMonth, l = endMonth + 1; i < l; i++) {
                    let num = i > 9 ? i.toString() : '0' + i;
                    let item = {
                        dataTime: `${year}-${num}`,
                        dataMonth: num
                    };
                    timeList.push(item)
                }
                console.log(timeList);
                this.params.timeList = timeList
            }
        },
        search() {
            this.params.currentPage = 1
            this.getAirTicketVOList();
        },
        getAirTicketVOList() {
            consumer_excessive_airTicket_deptAnalysis(this.params).then(res => {
                this.tableData = res.pageResult.pageData;
                this.params.total = res.pageResult.totalCount
            }).catch(() => {
                this.params.total = 0
            })
        }
    },
    created() {
        this.getDepartmentList()
    },
    activated() {
        this.setTimeList();
        this.getAirTicketVOList();
    },
    watch: {},
    computed: {}
};